<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingLotId">
          <SuggestParking v-model="query.parkingLotId" class="width260" />
        </el-form-item>
        <el-form-item prop="name">
          <!--          <SuggestParking v-model="query.parkingLotId" />-->
          <el-input v-model="query.name" placeholder="请输入商户名称查询" prefix-icon="el-icon-search"
            class="width260 margin-right10 margin-left38" />
        </el-form-item>
        <el-form-item prop="auditStatus">
          <el-select v-model="query.auditStatus" class="common-input" clearable placeholder="审核状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="冻结" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
            <!--            <el-option label="已拒绝" value="3"></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item prop="timeValue">
          <el-date-picker v-model="query.timeValue" end-placeholder="结束日期" prefix-icon="none" range-separator="至"
            start-placeholder="开始日期" type="daterange" unlink-panels value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ form.exportSize }}</div>
        </div>
      </div>
    </div>
    <div class="content-box section">
      <!--      v-loading="isLoading"-->
      <el-table :data="dataList" border class="common-table" size="medium" style="width: 100%">
        <el-table-column label="商户编号" prop="merchantCode"></el-table-column>
        <el-table-column label="商户名称" prop="merchantName">
        </el-table-column>
        <el-table-column label="关联车场" prop="parkingLotName" width="400">
          <template slot-scope="scope">
            {{ scope.row.parkingLotName.join("; ") }}
          </template>
        </el-table-column>
        <el-table-column label="注册账号" prop="account"></el-table-column>
        <el-table-column label="申请时间" prop="applyTime">
        </el-table-column>
        <!-- <el-table-column label="认证状态">
          <template slot-scope="scope">
            {{ scope.row.authorityStatus === 0 ? "未认证" : "已认证" }}
          </template>
        </el-table-column> -->
        <el-table-column label="商户状态">
          <template slot-scope="scope">
            {{ status[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button class="common-text" size="small" type="text" @click="goDetail(scope.row.id)">详情
            </el-button>
            <!--            <el-button-->
            <!--                v-if="scope.row.status === 1 && hasRights('001003003002')"-->
            <!--                class="common-text"-->
            <!--                size="small"-->
            <!--                type="text"-->
            <!--                @click="couponRecord(scope.row.userId)"-->
            <!--            >发券记录-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--                v-if="scope.row.status === 1 && hasRights('001003003003')"-->
            <!--                class="common-text"-->
            <!--                size="small"-->
            <!--                type="text"-->
            <!--                @click="showCouponConfig(scope.row.userId)">发券配置-->
            <!--            </el-button-->
            <!--            >-->
            <!--            <el-button-->
            <!--                v-if="scope.row.status === 0 && hasRights('001003003008')"-->
            <!--                class="common-text"-->
            <!--                size="small"-->
            <!--                type="text"-->
            <!--                @click="goVerify(scope.row.auditId)"-->
            <!--            >审核-->
            <!--            </el-button-->
            <!--            >-->
            <el-button class="common-text" size="small" type="text" @click="showRecharge(scope.row.id)">充值
            </el-button>
            <!--            v-if="scope.row.status === 1"-->
            <el-button v-if="scope.row.status === 0" class="common-text" size="small" type="text"
              @click="relationParkingLot(scope.row.id)">关联车场
            </el-button>
            <!--            v-if="scope.row.status === 2"-->
            <el-button v-if="scope.row.status === 2" class="common-text" size="small" type="text"
              @click="changeEnable(scope.row.id)">启用
            </el-button>
            <!--            v-if="scope.row.status === 1"-->
            <el-button v-if="scope.row.status === 0" class="common-text" style="color: red !important;" size="small"
              type="text" @click="changeDisable(scope.row.id)">禁用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize" :total="pagination.totalSize"
          background layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>

      <!-- 导出弹窗 -->
      <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
        width="600px">
        <el-form ref="form" :model="form" class="label-weight">
          <el-form-item label="导出条数" label-width="120px" prop="exportSize">
            <el-col :span="16">
              <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmExport">确认导出</el-button>
        </div>
      </el-dialog>

      <!--充值-->
      <el-dialog v-loading="isRechargeLoading" :visible.sync="rechargeDialogVisible" :close-on-click-modal="false"
        append-to-body class="common-dialog" title="充值" width="500px">
        <el-form ref="model" :inline="true" :model="model" :rules="rules" size="medium">
          <el-row>
            <el-col :span="24" v-if="model.nowaccount">
              <el-form-item label="当前余额" prop="nowaccount">
                {{ model.nowaccount }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="充值金额" prop="rechargeMoney">
                <el-input class="width300" v-model="model.rechargeMoney" placeholder="请填写充值金额" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="充值备注" prop="description">
                <el-input class="width300" v-model="model.description" placeholder="请填写充值备注" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelRecharge">取 消</el-button>
          <el-button type="primary" @click="saveRecharge">确 定</el-button>
        </span>
      </el-dialog>

      <!--关联车场-->
      <el-dialog :before-close="beforeRelationDialogClosed" :visible.sync="relationConfigDialogVisible" append-to-body
        class="common-dialog" title="关联车场" width="800px">
        <SuggestParking v-model="parkingLotId" @parkingName="getParkingName" />
        <div class="add-btn">
          <el-button type="primary" @click="addParkingLot" v-if="relationList.length == 0">添加</el-button>
        </div>

        <div>
          <el-table v-loading="isRelationLoading" :data="relationList" border class="common-table" size="medium"
            style="width: 100%">
            <el-table-column label="车场名称">
              <template slot-scope="scope">
                {{ scope.row.parkingLotName }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120">
              <template slot-scope="scope">
                <el-button class="common-text" size="small" type="text" @click="deleteParkingLot(scope.row.id)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--        <span slot="footer" class="dialog-footer">-->
        <!--          <el-button @click="cancelRelation">取 消</el-button>-->
        <!--          <el-button type="primary" @click="saveRelation">确 定</el-button>-->
        <!--        </span>-->
      </el-dialog>

      <!--发券配置-->
      <el-dialog v-loading="isFoundCouponLoading" :before-close="beforeCouponConfigDialogClosed"
        :visible.sync="couponConfigDialogVisible" append-to-body class="common-dialog" title="发券配置" width="500px">
        <el-row>
          <el-checkbox-group v-model="couponConfigList" class="margin-left38 margin-top20 width120"
            style="position: relative;" @change="changecheck">
            <el-checkbox class="checkbox-select" label="PLATE_NUMBER">车牌号码发券
            </el-checkbox>
            <el-checkbox class="checkbox-select" label="QRCODE">二维码发券
            </el-checkbox>
            <el-button class="top44 left148" v-if="showSet" size="mini" style="position: absolute;" type="primary"
              @click="setQuota"><span>设置定额</span>
            </el-button>
            <div class="top38 left148 width260" v-if="showQuotaInput" style="position: absolute;">
              <el-input class="width204" v-model="fixedAmount" placeholder="固定额度" size="mini">
                <template slot="prepend">当前设置额度</template>
              </el-input>
              <el-button class="margin-left10" type="text" @click="deleteQuotaInput"><span>删除</span></el-button>
            </div>
            <el-checkbox class="checkbox-select" label="FREE">免费放行
            </el-checkbox>
          </el-checkbox-group>
        </el-row>
        <!-- <el-row style="margin-left: 214px" v-if="showSet">
          <el-button type="text" @click="setQuota"
            ><span>点击设置定额</span></el-button
          >
        </el-row> -->
        <!-- <el-row
          style="margin-left: 206px; margin-top: 20px"
          v-if="showQuotaInput"
        >
          <span style="margin-left: -100px" v-if="showNow">当前设置额度：</span>
          <el-input
            placeholder="固定额度"
            v-model="fixedAmount"
            style="width: 100px"
            size="mini"
          />
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="deleteQuotaInput"
            ><span>删除</span></el-button
          >
        </el-row> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelCouponConfig">取 消</el-button>
          <el-button type="primary" @click="saveCouponConfig">保 存</el-button>
        </span>
      </el-dialog>

      <!-- 发券记录 -->
      <el-dialog :visible.sync="recordDialogVisible" append-to-body class="common-dialog" title="发券记录" width="1400px">
        <div class="content-box section">
          <el-table v-loading="isRecordLoading" :data="recordList" border class="common-table" size="medium"
            style="width: 100%">
            <el-table-column align="center" label="适用范围" prop="scopeOfApplication" width="180">
              <template slot-scope="scope">
                {{ scope.row.scopeOfApplication }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="已使用停车场" prop="parkingLotName" width="170">
            </el-table-column>
            <el-table-column align="center" label="车牌号码" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.vehicleNumber === ''"> " "</span>
                <span v-else>
                  <Numplate :type="scope.row.vehicleColor">{{
                    scope.row.vehicleNumber
                  }}</Numplate>
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="发券时间" prop="giveCouponTime" width="180">
            </el-table-column>
            <el-table-column align="center" label="使用时间" prop="useCouponTime" width="180">
            </el-table-column>
            <el-table-column align="center" label="优惠券编号" prop="couponNo" width="160">
            </el-table-column>
            <el-table-column align="center" label="优惠券金额" prop="couponAmount" width="100">
            </el-table-column>
            <el-table-column align="center" label="手机号码" width="140">
              <template slot-scope="scope">
                {{ scope.row.phoneNumber === "" ? "" : scope.row.phoneNumber }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="优惠券类型" prop="couponType">
              <template slot-scope="scope">
                {{ couponType[scope.row.couponType] }}
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination-box margin-top20" style="text-align: right">
            <el-pagination :current-page="pageNo" :page-size="pageSize" :total="count" background
              class="pagination-left" layout="sizes, prev, pager, next, jumper" @size-change="couponHandleSizeChange"
              @current-change="couponHandleCurrentChange">
            </el-pagination>
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="closed">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";
import { doExport } from "@/api/common";
import {
  curledHail,
  disableMerchant,
  enableMerchant,
  getPublishCouponTypeByUserId,
  merchantControllerList,
  merchantInParkingLot,
  delMerchantInParkingLot,
  selectCurledHailLog,
  findMerchantInParkingLot,
  // topSucceed,
  merchantAccountDetail,
  merchantAccountCharge
} from "@/api/user";
import {
  checkToken,
} from "@/api/common";
import { MessageBox } from "element-ui";

export default {
  name: "merchant-manage",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      // isMultiple: true, //停车场多选
      timeValue: [],
      query: {
        name: '',
        auditStatus: '',
        merchantId: "",
        // settlementId: "",
        registerId: "",
        authorityStatus: "",
        status: "",
        timeValue: [],
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },

      dataList: [],
      status: {
        0: "正常",
        1: "冻结",
        2: "禁用",
        // 3: "已拒绝",
      },
      rechargeDialogVisible: false,
      // 充值
      model: {
        rechargeMoney: "",
        description: "",
        nowaccount: ""
      },
      rechargeId: "", //充值userID
      rules: {
        rechargeMoney: [{ required: true, message: "充值金额不能为空" }],
        description: [{ required: false, message: "充值备注不能为空" }],
      },
      rechargeModel: {},
      couponConfigDialogVisible: false, //发券配置弹窗
      // couponConfigModel: {},
      couponConfigList: [], //发券配置列表
      fixedAmount: "", //发券配置---发券定额
      showSet: false, //是否显示设置定额按钮
      showQuotaInput: false, //是否显示定额输入框
      // showNow: false, //显示当前设置额度
      userId: "", //发券配置使用id
      recordDialogVisible: false, //发券记录弹窗
      recordList: [], //发券记录列表
      id: "", //查询发券记录，保存当前id，查分页数据使用
      pageNo: 1,
      pageSize: 10,
      count: 0, //发券记录总条数
      pages: 0, //总页数
      couponType: {
        0: "面额券",
        1: "时长券",
        2: "免费停车",
      },
      relationConfigDialogVisible: false, //关联车场
      relationId: "", //关联车场使用id
      parkingLotId: "", //选择的车场
      relationList: [], //展示车场列表
      relationListId: [], //保存用的车场id数组
      parkingName: "", //车场名称
      isLoading: false,
      isRecordLoading: false, //发券记录loading
      isRelationLoading: false, //关联车场loading
      isFoundCouponLoading: false, //发券配置loading
      isRechargeLoading: false, //充值loading
    };
  },
  components: {
    // Settlement,
    SuggestParking,
    Numplate,
  },
  methods: {
    //查看详情信息
    goDetail(e) {
      console.log(e)
      this.$router.push({
        path: "/user_manage/merchant_detail",
        query: {
          id: e,
        },
      });
    },
    //审核商户
    goVerify(e) {
      this.$router.push({
        path: "/user_manage/merchant_verify",
        query: {
          auditId: e,
        },
      });
    },
    //重置查询条件
    resetQueryForm() {
      this.query = {
        name: '',
        auditStatus: '',
        merchantId: "",
        // settlementId: "",
        registerId: "",
        authorityStatus: "",
        status: "",
        timeValue: [],
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      // this.query.timeValue = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.isLoading = true;
            const params = this.paramFormat(this.query);
            if (this.query.timeValue.length > 0) {
              params["startDate"] = this.query.timeValue[0];
              params["endDate"] = this.query.timeValue[1];
            }
            const res = await merchantControllerList(params);
            this.isLoading = false;
            if (res && res.code === 30 && res.returnObject) {
              const returnObject = res.returnObject;
              this.dataList = returnObject.records;
              this.pagination.totalSize = returnObject.total;
              this.pagination.totalPages = returnObject.pages;
              this.form.exportSize = returnObject.total;
            }
          }
        })
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValue.length > 0) {
        params["startDate"] = this.query.timeValue[0];
        params["endDate"] = this.query.timeValue[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/merchantControllerAdministrator/merchantControllerList?" + objectToQueryString(params));
      doExport(
        "/merchantControllerAdministrator/merchantControllerList",
        params,
        "商户管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //查询发券记录
    couponRecord(e) {
      this.recordDialogVisible = true;
      this.isRecordLoading = true;
      this.id = e;
      // const id = "8749BF5E61AA456A80AB7BBD4311353B";
      const id = this.id;
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      selectCurledHailLog(id, pageNo, pageSize).then((res) => {
        this.isRecordLoading = false;
        if (res && res.code === 30 && res.result) {
          this.recordList = res.returnObject.list;
          this.count = res.returnObject.count;
          this.pages = res.returnObject.totalPage;
        }
      });
    },
    couponHandleSizeChange(e) {
      this.pageSize = e;
      this.couponRecord(this.id);
    },
    couponHandleCurrentChange(e) {
      this.pageNo = e;
      this.couponRecord(this.id);
    },
    //关闭发券记录弹窗
    closed() {
      this.recordList = [];
      this.pageSize = 10;
      this.pageNo = 1;
      this.recordDialogVisible = false;
    },
    //充值
    showRecharge(e) {
      this.model.rechargeMoney = 0;
      merchantAccountDetail(e).then(res => {
        if (res && res.code == 30) {
          this.model.nowaccount = res.returnObject.account;
        }
      })
      this.rechargeDialogVisible = true;

      this.rechargeId = e;
    },
    //取消充值
    cancelRecharge() {
      this.rechargeDialogVisible = false;
      this.$refs.model.resetFields();
    },
    //确认充值
    saveRecharge() {
      this.$refs.model.validate(async (valid) => {
        if (valid) {
          const data = {
            userId: this.rechargeId,
            amount: this.model.rechargeMoney,
            comment: this.model.description,
          };
          const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
          if (!reg.test(data.amount)) {
            this.$message.error("充值金额不能为负数且小数点后只保留2位");
            return false;
          }
          this.isRechargeLoading = true;
          merchantAccountCharge(data).then((res) => {
            this.isRechargeLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "充值成功",
                type: "success",
              });
              this.rechargeDialogVisible = false;
              this.$refs.model.resetFields();
            }
          }).catch(() => {
            this.isRechargeLoading = false;
            this.$message({
              message: "充值失败",
              type: "error",
            });
          });
        }
      });
    },
    //发券配置显示弹窗
    showCouponConfig(e) {
      this.couponConfigDialogVisible = true;
      this.userId = e;
      this.isFoundCouponLoading = true;
      getPublishCouponTypeByUserId(e).then((res) => {
        this.isFoundCouponLoading = false;
        if (res && res.code === 30 && res.result) {
          const list = res.returnObject;
          if (list.length === 0) {
            this.couponConfigList = [];
            this.fixedAmount = "";
            this.showSet = false;
            this.showQuotaInput = false;
            // this.showNow = false;
          } else {
            const publishType = list.map((item) => {
              return item.publishType;
            });
            this.couponConfigList = publishType;
            const fixedAmount = list.filter((item) => {
              // debugger;
              // if (item.publishType === "QRCODE") {
              //   return item.fixedAmount;
              // }
              return item.publishType === "QRCODE";
            });
            if (fixedAmount[0].fixedAmount !== null) {
              this.fixedAmount = fixedAmount[0].fixedAmount;
              this.showSet = false;
              this.showQuotaInput = true;
              // this.showNow = true;
            } else {
              this.showSet = true;
            }
          }
        }
      });
    },
    //监听发券配置选项
    changecheck() {
      const checkQRcode = this.couponConfigList.indexOf("QRCODE");
      if (checkQRcode !== -1) {
        this.showSet = true;
      } else {
        this.showSet = false;
        this.showQuotaInput = false;
        this.fixedAmount = "";
      }
    },
    //点击设置定额显示输入框
    setQuota() {
      this.showSet = false;
      this.showQuotaInput = true;
    },
    //删除定额输入框
    deleteQuotaInput() {
      this.showQuotaInput = false;
      this.fixedAmount = "";
      // this.showNow = false;
      this.showSet = true;
    },
    //取消发券配置
    cancelCouponConfig() {
      this.couponConfigDialogVisible = false;
      this.couponConfigList = [];
      this.showSet = false;
      this.showQuotaInput = false;
      this.fixedAmount = "";
    },
    //关闭弹窗清空表单数据
    beforeCouponConfigDialogClosed(done) {
      this.couponConfigList = [];
      this.showSet = false;
      this.showQuotaInput = false;
      this.fixedAmount = "";
      done();
    },
    //保存发券配置
    saveCouponConfig() {
      if (this.showQuotaInput) {
        const fixedAmount = this.fixedAmount;
        const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
        if (fixedAmount === "") {
          this.$message({
            message: "请输入定额或删除定额",
            type: "warning",
          });
          return false;
        } else if (!reg.test(fixedAmount)) {
          this.$message.error("固定额度不能为负数且小数点后只保留2位");
          return false;
        }
      }
      const data = {
        id: this.userId,
        publishTypeList: this.couponConfigList,
        fixedAmount: this.fixedAmount,
      };
      this.isFoundCouponLoading = true;
      curledHail(data).then((res) => {
        this.isFoundCouponLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.couponConfigDialogVisible = false;
          this.couponConfigList = [];
          this.doSearch();
        }
      });
    },
    //关联车场弹窗
    relationParkingLot(e) {
      this.relationConfigDialogVisible = true;
      this.relationId = e;
      this.isRelationLoading = true;
      findMerchantInParkingLot(e).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.isRelationLoading = false;
          this.relationList = res.returnObject;
          let list = res.returnObject.map((item) => {
            return item.id;
          });
          this.relationListId = list;
        }
      });
    },
    //取消关联车场
    cancelRelation() {
      this.relationConfigDialogVisible = false;
      this.parkingLotId = "";
      this.relationList = [];
      this.relationListId = [];
      this.search();
    },
    //关闭弹窗清理表单
    beforeRelationDialogClosed(done) {
      this.parkingLotId = "";
      this.relationList = [];
      this.relationListId = [];
      done();
      this.search();
    },
    //获取车场名称
    getParkingName(data) {
      this.parkingName = data;
    },
    //关联车场添加
    addParkingLot() {
      const parkingId = this.parkingLotId;
      const relationList = this.relationList;
      if (parkingId === "") {
        return false;
      }
      console.log(relationList, parkingId)
      if (relationList.filter(r => r.parkinglotId === parkingId).length > 0) {
        this.$message.error('已关联该车场!')
        return false;
      }
      let list = relationList.findIndex((item) => {
        return item.id === parkingId;
      });
      if (list === -1) {
        this.relationList.push(this.parkingName);
        this.relationListId.push(this.parkingLotId);
        merchantInParkingLot({ userId: this.relationId, parkingLotIds: this.parkingLotId }).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.$message({
              message: "关联车场成功",
              type: "success",
            });
            findMerchantInParkingLot(this.relationId).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.isRelationLoading = false;
                this.relationList = res.returnObject;
                let list = res.returnObject.map((item) => {
                  return item.id;
                });
                this.relationListId = list;
              }
            });
          }
        });
      } else {
        this.$message.error("请勿重复添加车场");
        return false;
      }
    },
    //保存关联车场
    saveRelation() {
      const data = {
        id: this.relationId,
        parkingIdList: this.relationListId,
      };
      merchantInParkingLot(data).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "关联车场成功",
            type: "success",
          });
          this.relationConfigDialogVisible = false;
          this.doSearch();
          this.parkingLotId = "";
          this.relationList = [];
          this.relationListId = [];
        }
      });
    },
    //删除已添加的车场
    deleteParkingLot(e) {
      // const relationList = this.relationList;
      // const relationListId = this.relationListId;
      const id = e;
      // relationList.splice(relationList.indexOf(id), 1);
      // relationListId.splice(relationListId.indexOf(id), 1);
      delMerchantInParkingLot(id).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "取消关联成功",
            type: "success",
          });
          findMerchantInParkingLot(this.relationId).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.isRelationLoading = false;
              this.relationList = res.returnObject;
              let list = res.returnObject.map((item) => {
                return item.id;
              });
              this.relationListId = list;
            }
          });
          // 更新父列表
          this.doSearch();
          // this.relationConfigDialogVisible = false;
          // this.doSearch();
          // this.parkingLotId = "";
          // this.relationList = [];
          // this.relationListId = [];
        }
      });
    },
    //启用
    changeEnable(e) {
      const data = {
        id: e,
      };
      this.$confirm("确定要启用该商户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        enableMerchant(data).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.$message.success("启用商户成功！");
            this.doSearch();
          }
        });
      });
    },
    //禁用
    changeDisable(e) {
      const data = {
        userId: e,
      };
      this.$confirm("确定要禁用该商户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        disableMerchant(data).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.$message.success("禁用商户成功！");
            this.doSearch();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.add-btn {
  text-align: right;
  margin-top: 12px;
  margin-bottom: 14px;
}

.pagination-left /deep/ .el-pagination__sizes {
  float: left;
}

.checkbox-select {
  display: block;
  margin-bottom: 30px;
}

/deep/ .el-button--text {
  color: #0768FD !important;
}
</style>
